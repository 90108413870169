import MessageApi from '../../../../api/inbox/message';
import ConversationApi from '../../../../api/inbox/conversation';
import { useAlert } from 'dashboard/composables';

export default {
  async translateReply(_, { conversationId, replyContent }) {
    try {
      const response = await ConversationApi.translateReply(conversationId, replyContent);
      const { content } = response.data;
      return content;
    } catch (error) {
      const errorData = error.response.data.error;
      const errorMessage =
          errorData?.error?.message || 'Đã có vấn đề xảy ra trong quá trình dịch văn bản, liên hệ admin để giải quyết';
      useAlert(errorMessage);
    }
    return replyContent;
  },

  async translateMessage(
    _,
    { conversationId, messageId, targetLanguage, count = 1 }
  ) {
    try {
      await MessageApi.translateMessage(
        conversationId,
        messageId,
        targetLanguage,
        count
      );
    } catch (error) {
      const errorData = error.response.data.error;
      const errorMessage =
          errorData?.error?.message || 'Đã có vấn đề xảy ra trong quá trình dịch văn bản, liên hệ admin để giải quyết';
      useAlert(errorMessage);
    }
  },
};
